import React from "react";
import { Route } from "react-router-dom";
import DefaultInnerPage from "../../layout/DefaultInnerPage/DefaultInnerPage";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import TOSDetail from "./TOSDetail";

export default function TOS() {
  return (
    <DefaultLayout>
      <DefaultInnerPage pagename="Terms of service">
        <Route path="/tos" exact>
          <TOSDetail />
        </Route>
      </DefaultInnerPage>
    </DefaultLayout>
  );
}
