import FadeIn from "react-fade-in";
import "./Loader.scss";

export default function Loader() {
  return (
    <FadeIn className="loader-wrapper">
      <div className="loader">Loading...</div>
    </FadeIn>
  );
}
