import { HashLink as Link } from "react-router-hash-link";
import { EMAIL, PHONE } from "../../constant/app";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="footer-info">
                <h3>Z-Face Rigging</h3>
                <p className="pb-3">
                  <em>
                    Developed by: <br />
					ศูนย์เทคโนโลยีอิเล็กทรอนิกส์และคอมพิวเตอร์แห่งชาติ,
					สำนักงานพัฒนาวิทยาศาสตร์และเทคโนโลยีแห่งชาติ <br />
		
					National Electronics and Computer Technology Center, 
					National Science and Technology Development Agency.
                  </em>
                </p>
                <p>
                  112 Thailand Sceince Park, Phahonyothin Rd. <br />
                  12120, Thailand
                  <br />
                  <br />
                  <strong>Phone: </strong> 02 564 6900
                  <br />
                  <strong>Email:</strong> {EMAIL}
                  <br />
                </p>
                <div className="social-links mt-3">
                  <a href="#None" className="facebook">
                    <i className="bx bxl-facebook"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link smooth to="/#">Home</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link smooth to="/#about">About us</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link smooth to="/#services">Services</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link smooth to="/tos#">Terms of service</Link>
                </li>
              </ul>
            </div>

            {/* <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#None">Z Face Rigging</a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
