import { forwardRef } from "react";
import ImgService from "../../assets/img/service/z-face-rigging.jpg";

function Services(props: any, ref: any) {
  return (
    <section ref={ref} id="services" className="services">
      <div className="container">
        <div className="section-title" data-aos="fade-in" data-aos-delay="100">
          <h2>Z-Face Rigging Services</h2>
          <p>
            Z-Face Rigging is an application for everyone who is interested in manipulate 3D avatar model that can express facial expressions as same as user expressions. 
			Additionally, the application can be automated online and rendered in real-time using Three.js. 
			It combines the use of the Facemesh API for the javascript version of TensorFlow for face tracking and jeelizWeboji API by Feeliz for blink detection. 
			
          </p>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-12 d-flex align-items-stretch mb-5 mb-lg-4">
            <img
              style={{ width: "100%", height: "auto", borderRadius: 10 }}
              alt="service1"
              src={ImgService}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4 className="title">
                <a href="#None">Avatar models</a>
              </h4>
              <p className="description">
                Avatar models are available in both genders: lady and gentleman with three dress sets. Users can interact with the 3D model such as translation, rotation and scaling.
              </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4 className="title">
                <a href="#None">Inputs: Webcam / MP4</a>
              </h4>
              <p className="description">
                Users can control or manipulate avatar's face according to user's face via both Webcam or MP4.
              </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon">
                <i className="bx bx-tachometer"></i>
              </div>
              <h4 className="title">
                <a href="#None">Face / Eye blink Tracking</a>
              </h4>
              <p className="description">
                In this application, the TensorFlow.js named Facemesh API was applied. 
				A template of 486-point coordinates is fit to the face image to get face coordinates in both two and three dimensions. 
				Also jeelizFaceTransfer by Feeliz is applied for the eye-blinking detection algorithm as well.
				
              </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4 className="title">
                <a href="#None">Real-time and Online.</a>
              </h4>
              <p className="description">
                Z-Face Rigging generated high quality, real-time and online results for 3D display and face manipulation through web browsers..
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default forwardRef(Services);
