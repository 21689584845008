import DefaultInnerPage from "../../layout/DefaultInnerPage/DefaultInnerPage";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import RegisterForm from "./RegisterForm";

export default function Register() {
  return (
    <DefaultLayout>
      <DefaultInnerPage pagename="Register">
        <RegisterForm></RegisterForm>
      </DefaultInnerPage>
    </DefaultLayout>
  );
}
