import "./TOSDetail.scss";

export default function TOSDetail() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 p-indent">
          <h2>ข้อกำหนดและเงื่อนไขการใช้ระบบ “Z-Face Rigging”</h2>
          <p>ขอต้อนรับสู่ระบบ “Z-Face Rigging” ผู้ใช้งานจะต้องอ่านและทำความเข้าใจข้อกำหนดและเงื่อนไขการใช้ระบบโดยละเอียดก่อนการใช้งานระบบ การใช้งานระบบจากอุปกรณ์ถือเป็นการยอมรับข้อกำหนดและเงื่อนไขการใช้ระบบนี้ หากผู้ใช้งานไม่ยอมรับข้อกำหนดและเงื่อนไขการใช้ระบบนี้ ผู้ใช้งานต้องไม่เข้าใช้งานระบบ หรือลบระบบนี้ออกจากอุปกรณ์หากสามารถกระทำได้</p>
          <h3>๑. คำนิยาม </h3>
          <p><strong>“NECTEC”</strong> หมายความถึง ศูนย์เทคโนโลยีอิเล็กทรอนิกส์และคอมพิวเตอร์แห่งชาติ ซึ่งเป็นผู้พัฒนาระบบ </p>
          <p><strong>“ผู้ใช้งาน”</strong> หมายความถึง บุคคลที่เข้าใช้งานระบบ ไม่ว่าจะได้มีการลงทะเบียนผ่านทางระบบหรือไม่</p>
          <p><strong>“อุปกรณ์”</strong> หมายความรวมถึงแต่ไม่จำกัดเพียง โทรศัพท์มือถือ คอมพิวเตอร์ แท็บเล็ต หรืออุปกรณ์อื่นซึ่งผู้ใช้งานสามารถใช้ในการเข้าถึงการใช้งานระบบได้ </p>
          <p><strong>“ข้อมูลส่วนบุคคล”</strong> หมายความว่า ข้อมูลใดๆ เกี่ยวกับสิ่งเฉพาะตัวของผู้ใช้งาน เช่น ชื่อ profile เป็นต้น</p>
          <p><strong>“โรงพยาบาล”</strong> หมายความว่า โรงพยาบาลที่ได้รับการคัดเลือกหรือมีความร่วมมือกับ NECTEC</p>
          <h3>๒. วัตถุประสงค์ของการใช้ระบบ</h3>
          <p>ระบบนี้ใช้เป็นเครื่องมือตรวจจับใบหน้าและควบคุมใบหน้าของโมเดล 3 มิติ </p>
          <h3>๓. ขอบเขตการอนุญาตให้ใช้แอพพลิเคชั่น </h3>
          <p>NECTEC อนุญาตให้ผู้ใช้งานใช้งานระบบนี้เพื่อเป็นเครื่องมือในการตรวจจับใบหน้าและควบคุมใบหน้าของโมเดล 3 มิติ ผ่านอุปกรณ์เท่านั้น</p>
          <h3>๔. คำรับรองของผู้ใช้งาน</h3>
          <p>๔.๑ ผู้ใช้งานรับทราบว่าการใช้งานระบบนี้มีการเปิดเผยหรือบันทึกข้อมูลส่วนบุคคลของผู้ใช้งานลงในระบบ และรับรองว่าผู้ใช้งานเป็นเจ้าของ และเป็นผู้มีสิทธิโดยชอบธรรมในข้อมูลส่วนบุคคลที่ได้มีการเปิดเผยหรือบันทึกลงในระบบนี้</p>
          <p>๔.๒ ในกรณีที่ผู้ใช้งานเป็นผู้เยาว์ ผู้ใช้งานรับรองว่าได้รับความยินยอมจากผู้แทนโดยชอบธรรมหรือผู้ปกครองตามกฎหมายและในการยอมรับข้อกำหนดและเงื่อนไขการใช้ระบบ</p>
          <p>๔.๓ ผู้ใช้งานงานตกลงและยอมรับว่า NECTEC มีสิทธิที่จะแก้ไข เปลี่ยนแปลง หรือยกเลิกข้อตกลงและเงื่อนไขการใช้งานระบบนี้ได้ ตามที่ NECTEC เห็นสมควร โดยไม่ต้องแจ้งให้ทราบล่วงหน้า เพื่อประโยชน์ในการพัฒนาหรือปรับปรุงการใช้บริการระบบ หรือเพื่อปฏิบัติการให้เป็นไปตามบทบัญญัติของกฎหมายที่เกี่ยวข้องทั้งที่ใช้บังคับอยู่และที่จะออกใช้บังคับในอนาคต</p>
          <p>๔.๔ ผู้ใช้งานจะต้องรักษาชื่อผู้ใช้งาน (Username) และรหัสผ่าน (Password) ในการเข้าใข้งานระบบไว้เป็นความลับ</p>
          <p>๔.๕ ผู้ใช้งานตกลงจะไม่กระทำการใดๆ อันเป็นการละเมิดสิทธิในทรัพย์สินทางปัญญาหรือสิทธิอื่นใดตามกฎหมายของระบบ ไม่ว่าทั้งหมดหรือแต่เพียงบางส่วน ซึ่งรวมถึงแต่ไม่จำกัดเพียง การทำซ้ำ การทำวิศวกรรมย้อนกลับ การถอดรหัส
          </p>
          <p>๔.๖ ผู้ใช้งานตกลงและยอมรับว่าในกรณีที่มีการใช้ข้อมูลในกุญแจส่วนตัว (Private Key) ในการสร้างลายมือชื่ออิเล็กทรอนิกส์เพื่อการเข้าใช้งานระบบ ผู้ใช้งานตกลงและยินยอมให้ลายมื่อชื่ออิเล็กทรอนิกส์ดังกล่าวมีความผูกพันตามกฎหมายเสมือนเป็นการลงลายมือชื่อของผู้ใช้งาน ในเอกสารนิติกรรม หรือสัญญา และยินยอมผูกพันตามเนื้อหาหรือข้อมูลนั้นทุกประการ</p>
          <h3>๕. การยินยอมให้ใช้ข้อมูลส่วนบุคคล</h3>
          <p>ผู้ใช้งาน (รวมทั้งผู้แทนโดยชอบธรรมหรือผู้ปกครองตามกฎหมายในกรณีผู้ใช้งานเป็นผู้เยาว์) ยอมรับและตกลงยินยอมให้ NECTEC และโรงพยาบาลใช้ข้อมูลส่วนบุคคลของผู้ใช้งานในขอบเขตของงานภายใต้วัตถุประสงค์ของการใช้งานระบบ</p>
          <h3>๖. ข้อจำกัดความรับผิดของ NECTEC</h3>
          <p>๖.๑ ข้อมูลที่ผู้ใช้งานได้รับจากการใช้ระบบเหล่านี้เป็นเพียงการให้คำแนะนำและการให้ข้อมูลในเบื้องต้นเท่านั้น มิได้มุ่งหมายเพื่อทดแทนผลการตรวจสุขภาพของแพทย์ หรือผู้ประกอบวิชาชีพเวชกรรม โดย NECTEC จะไม่รับผิดชอบในการที่ผู้ใช้งานนำคำแนะนำหรือข้อมูลดังกล่าวไปใช้ประโยชน์หรืออ้างอิงใดๆ ทั้งนี้ NECTEC แนะนำให้ใช้ข้อมูลอื่นๆ ประกอบการพิจารณาคำแนะนำหรือข้อมูลดังกล่าว และแนะนำให้ปรึกษาหรือดำเนินการเข้ารับการตรวจกับแพทย์และผู้ประกอบวิชาชีพเวชกรรมโดยตรง เพื่อนำผลการตรวจสุขภาพมาประกอบการพิจารณาข้อมูลจากระบบนี้
          </p>
          <p>๖.๒ ผู้ใช้งานจะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้ระบบนี้อย่างเคร่งครัด เพื่อความปลอดภัยในข้อมูลส่วนบุคคลของผู้ใช้งาน ในกรณีที่เกิดความสูญหายหรือเสียหายต่อผู้ใช้งาน อันเกิดจากผู้ใช้งานไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้งานระบบนี้ หรือการจงใจหรือประมาทเลินเล่อของผู้ใช้งาน หรือเหตุใดๆ ซึ่งอยู่นอกเหนือความรับผิดชอบของ NECTEC NECTEC ขอสงวนสิทธิในการปฏิเสธ ความรับผิดชอบสำหรับความสูญหายหรือเสียหายจากเหตุดังกล่าวทั้งหมด
          </p>
          <p>๖.๓ ระบบนี้ไม่ได้มีวัตถุประสงค์ทางการค้า หรือการอื่นใดนอกเหนือจากวัตถุประสงค์ดังกล่าวข้างต้น</p>
          <h3>๗.ทรัพย์สินทางปัญญา</h3>
          <p>ทรัพย์สินทางปัญญาของระบบ หรือที่ปรากฎในการใช้งานระบบนี้เป็นของ ศูนย์เทคโนโลยีอิเล็กทรอนิกส์และคอมพิวเตอร์แห่งชาติ การทำซ้ำ ลอกเลียน ดัดแปลง คัดลอก โฆษณา เผยแพร่กระจาย จำหน่าย จ่ายแจก วิศวกรรมย้อนกลับ ทั้งนี้ไม่ว่าบางส่วนหรือทั้งหมด หรือกระทำการใดอันเป็น การละเมิดสิทธิในทรัพย์สินทางปัญญา โดยมิได้รับอนุญาตจากเป็นลายลักษณ์อักษรเจ้าของสิทธิในทรัพย์สินทางปัญญา ถือเป็นการละเมิดสิทธิในทรัพย์สินทางปัญญา และอาจถูกดำเนินคดีตามกฎมาย
          </p>
          <hr/>
          <h2>Terms &amp; Conditions</h2>
          <p>By clicking a box acceptance, the user accepts these terms and conditions that the user information are collected with confidentiality protection and used for research purposes.</p>
        </div>
      </div>
    </div>
  );
}
