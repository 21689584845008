import FadeIn from "react-fade-in";

export type IModalProp = {
  uid: string;
  title: string;
  body: string;
  btn?: string;
  secondaryBtn?: string;
  remove?: Function;
  cb?: Function;
};

export default function Modal(props: IModalProp) {
  return (
    <div className="modal" tabIndex={-1}>
      <FadeIn>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button
                type="button"
                className="btn bx bx-lg bx-x"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ transform: "scale(1.5)" }}
                onClick={() => {
                  props.remove && props.remove(props.uid);
                  props.cb && props.cb(false);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p>{props.body}</p>
            </div>
            <div className="modal-footer">
              {props.secondaryBtn && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    props.remove && props.remove(props.uid);
                    props.cb && props.cb(false);
                  }}
                >
                  {props.secondaryBtn}
                </button>
              )}
              {props.btn && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    props.remove && props.remove(props.uid);
                    props.cb && props.cb(true);
                  }}
                >
                  {props.btn}
                </button>
              )}
            </div>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}
