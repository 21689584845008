import React from "react";
import "./DefaultLayout.scss";
import Footer from "./Footer";
import Header from "./Header";

export default function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
}
type DefaultLayoutProps = {
  children?: React.ReactNode;
};
