import { useEffect } from "react";
import { useHistory } from "react-router";
import { useQuery } from "../../hooks/useQuery";
import { IApiDataError, IApiDataSuccess } from "../../services/api.services";
import { useStoreActions } from "../../store/Hooks";

export default function Validate() {
  const validate = useStoreActions((action) => action.user.validate);
  const { alertError, alertSuccess } = useStoreActions(
    (action) => action.alert
  );
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      validate({ token })
        .then((resp: IApiDataSuccess) => {
          alertSuccess(resp);
        })
        .catch((err: IApiDataError) => {
          alertError(err);
        })
        .finally(() => {
          history.replace({ pathname: "/" });
        });
    }
  }, [alertError, alertSuccess, history, query, validate]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8">
          <h3>Validate email in progress...</h3>
        </div>
      </div>
    </div>
  );
}
