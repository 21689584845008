import DefaultInnerPage from "../../layout/DefaultInnerPage/DefaultInnerPage";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import LoginForm from "./LoginForm";

export default function Login() {
  return (
    <DefaultLayout>
      <DefaultInnerPage pagename="Login">
        <LoginForm></LoginForm>
      </DefaultInnerPage>
    </DefaultLayout>
  );
}
