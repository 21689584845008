
import { AxiosResponse } from 'axios';
import { Action, action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { addBearerFromObject, IApiDataSuccess, webApi } from '../services/api.services';
import { IApiDataError, parseData } from './../services/api.services';
export type Role = 'admin' | 'user'

export interface IUser {
  _id: string;
  isValidate: boolean;
  loggedBy: string;
  name?: string;
  role: Role;
  email: string;
  providerFacebook?: any;
  providerGoogle?: any;
}

export interface IUserSignupLocal {
  email: string;
  password: string;
  name: string;
}

export interface IUserSigninLocal {
  email: string;
  password: string;
  remember: boolean
}

export interface IUserForgotLocal {
  email: string;
}

//
export interface IEditPwdToken {
  token: string;
  password: string;
}
export interface IEditPwdOld {
  old_password: string;
  password: string;
}

export interface IEditProfileOld {
  old_password: string;
  password?: string;
  name?: string;
  email?: string;
  role?: string;
  isValidate?: string;
}
export interface IEditProfileToken {
  token: string;
  password?: string;
  name?: string;
  email?: string;
  role?: string;
  isValidate?: string;
}



export interface IUserModel {
  getUserStorage: Computed<IUserModel, IUser>; // check if user is already logged in
  setUserStorage: Action<IUserModel, IUser>;
  isLoggedIn: Computed<IUserModel, boolean>;
  isLocal: Computed<IUserModel, boolean>;
  isLoaded: boolean,
  setIsLoaded: Action<IUserModel, boolean>

  user: IUser
  setUser: Action<IUserModel, IUser>

  me: Thunk<IUserModel>;
  signup: Thunk<IUserModel, IUserSignupLocal>
  signin: Thunk<IUserModel, IUserSigninLocal>
  signout: Thunk<IUserModel>
  forgot: Thunk<IUserModel, IUserForgotLocal>
  editProfile: Thunk<IUserModel, IEditProfileOld | IEditProfileToken>
  changePassword: Thunk<IUserModel, IEditPwdToken | IEditPwdOld>
  jwt: Thunk<IUserModel, { token: string }>
  validate: Thunk<IUserModel, { token: string }>
  resend: Thunk<IUserModel, { email: string }>

  facebook: Thunk<IUserModel, { facebook: any }>
  // facebookCallback: Thunk<IUserModel, { facebook: any }>
  google: Thunk<IUserModel, { google: any }>
  // googleCallback: Thunk<IUserModel, { google: any }>

  restoreUser: Thunk<IUserModel>
}

export const user: IUserModel = {
  getUserStorage: computed((state) => {
    const sessionBase64: string | null = window.localStorage.getItem("s")
    if (sessionBase64 === null) {
      return {} as IUser
    }
    const sessionStr = atob(sessionBase64)
    const user = JSON.parse(sessionStr) as IUser
    return user
  }),
  setUserStorage: action((state, user) => {
    const sessionBase64 = btoa(JSON.stringify(user))
    window.localStorage.setItem("s", sessionBase64)
  }),
  isLoggedIn: computed(state => {
    return state.user._id !== undefined && state.user._id !== ""
  }),
  isLocal: computed(state => {
    if (state.isLoggedIn) {
      if (['local', 'jwt'].includes(state.user.loggedBy)) {
        return true
      }
      return false
    }
    return false
  }),
  isLoaded: false,
  setIsLoaded: action((state, isLoaded) => {
    state.isLoaded = isLoaded
  }),
  user: {} as IUser,
  setUser: action((state, user) => {
    state.user = user;
  }),
  // 
  me: thunk(async () => {
    return await webApi.get('user/me');
  }),
  signup: thunk(async (_, signupForm: IUserSignupLocal) => {
    return await webApi.post('auth/signup', signupForm)
  }),
  signin: thunk(async (_, signinForm: IUserSigninLocal) => {
    return await webApi.post('auth/signin', signinForm)
  }),
  signout: thunk(async (_) => {
    return await webApi.get('auth/signout')
  }),
  forgot: thunk(async (_, forgotForm: IUserForgotLocal) => {
    return await webApi.post('auth/forgot', forgotForm)
  }),
  editProfile: thunk(async (_, editForm: IEditProfileOld | IEditProfileToken) => {
    const headers = { ...addBearerFromObject(editForm) }
    return await webApi.patch('user', editForm, { headers })
  }),
  changePassword: thunk(async (_, changeForm: IEditPwdOld | IEditPwdToken) => {
    const headers = { ...addBearerFromObject(changeForm) }
    return await webApi.patch('user', changeForm, { headers })
  }),
  jwt: thunk(async (_, { token }) => {
    return await webApi.get('auth/jwt', { headers: { 'Authorization': token } })
  }),
  validate: thunk(async (_, { token }) => {
    return await webApi.get('auth/validate', { headers: { 'Authorization': `Bearer ${token}` } })
  }),
  resend: thunk(async (_, { email }) => {
    return await webApi.post('auth/resend', { email: email })
  }),
  // 
  facebook: thunk(async (_, data) => {
    throw new Error('not implemented')
  }),
  google: thunk(async (_, data) => {
    throw new Error('not implemented')
  }),
  // 
  restoreUser: thunk(async (actions) => {
    await actions.me().then((resp: AxiosResponse<IApiDataSuccess<{ user: IUser }>>) => {
      actions.setUser(parseData(resp).user);
    }).catch((error: AxiosResponse<IApiDataError>) => {
      // console.log(error)
      // not authorized
    }).finally(() => {
      actions.setIsLoaded(true);
    })
  })
}