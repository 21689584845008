import React from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";
import ChangePasswordForm from "../../components/Forms/ChangePasswordForm";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import DefaultInnerPage from "./../../layout/DefaultInnerPage/DefaultInnerPage";
import Facebook from "./Facebook";
import GoneWrong from "./GoneWrong";
import Loading from "./Loading";
import Validate from "./Validate";

export default function Callback() {
  const { path } = useRouteMatch();

  return (
    <DefaultLayout>
      <DefaultInnerPage pagename="Callback">
        <Switch>
          <Route exact path={path}>
            <GoneWrong />
          </Route>
          <Route path={`${path}/facebook`}>
            <Facebook />
          </Route>
          <Route path={`${path}/google`}>
            <Loading />
            {/* google is good no fail :) */}
          </Route>
          <Route path={`${path}/validate`}>
            <Loading />
            {/* Validate token from email after register */}
            <Validate />
          </Route>
          <Route path={`${path}/reset`}>
            <ChangePasswordForm changeBy="token" />
          </Route>
        </Switch>
        <div style={{ height: 300 }} />
      </DefaultInnerPage>
    </DefaultLayout>
  );
}
