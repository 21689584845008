import { Action, action } from "easy-peasy";

export interface IHomeNavigateModel {
  node: React.RefObject<HTMLElement>
  setNode: Action<IHomeNavigateModel, React.RefObject<HTMLElement>>
}

export const homeNavigate: IHomeNavigateModel = {
  node: {} as React.RefObject<HTMLElement>,
  setNode: action((state, node) => {
    state.node = node;
  }),
}