import { forwardRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useStoreState } from './../../store/Hooks';

function About(props: any, ref: any) {
  const isLoggedIn = useStoreState((state) => state.user.isLoggedIn);

  return (
    <section ref={ref} id="about" className="about">
      <div className="container">
        <div
          className="section-title py-0"
          data-aos="fade-in"
          data-aos-delay="100"
        >
          <h2>About</h2>
        </div>

        <div className="row no-gutters">
          <div
            className="content col-xl-5 d-flex align-items-stretch"
            data-aos="fade-up"
          >
            <div className="content">
              <h3>Z-Face Rigging: 3D Facial Animation.</h3>
              <p>
                Z-Face Rigging is a face control system for 3D avatar with webcam or MP4 using the Facemesh API for face tracking and Jeeliz-transfer API for eye tracking. 
				3D avatar face animation is developed as a responsive web application. It starts with face detection and tracking through the webcam/MP4. 
				Face coordinate data is normalized to a vertical face view where the distance between the eyes and the level of the eyes are the same for each video frame. 
				Then new face coordinates are calculated in both 2D and 3D to study the change of specific coordinates such as mouth shape and face shape. 
				In addition, the coordinates of face structures in the video platform have also been added to the 3D model platform. 
				Specific coordinates are studied to analyze distance changes to be applied in 3D avatar manipulation. The 3D models are designed and created in multiple blend-shapes or basic character facial features. 
				Our 3D facial animations with a webcam/MP4 generate results of high quality, real-time and online simulation. Therefore, our work is a fundamental technology that can be applied to animate other 3D characters in blend-shape format.
              </p>
              {/* <button
                className="about-btn btn"
                onClick={() => alert({ body: "hi" })}
              >
                Learn more... <i className="bx bx-chevron-right"></i>
              </button> */}
			  <section id="zfacerig" className="zfacerig">
              { isLoggedIn ? 
			   
                <Link to="/" className="about-btn btn" onClick={() =>
                  window.location.replace(
                    window.location.origin + "/rigging/"
                  )
                }>
				
				  <h2>Try Z-Face Rigging</h2>
                  Try z-face rigging CLICK! + more <i className="bx bx-chevron-right"></i>
                </Link> :
                <Link smooth to="/login#" className="about-btn btn">
				  <h2>Z-Face Rigging App</h2>
                  Try z-face rigging CLICK! + more <i className="bx bx-chevron-right"></i>
                </Link>

              }
			  </section>
            </div>
          </div>
          <div className="col-xl-7 d-flex align-items-stretch">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i className="bx bx-receipt"></i>
                  <h4>Related article</h4>
                  <p>
                    Chanjira Sinthanayothin, Wisarut Bholsithi, Nonlapas Wongwaen. “Morph Targets for 3D Facial Animation with Webcam Using Facemesh, Jeeliz-tranfer APIs and Three.js”.
					The 13th International Conference on Digital Image Processing (ICDIP 2021), 28-30 May 2021, Singapore. (SPIE Proceeding is in progress)
                    ( <a href="https://www.spiedigitallibrary.org/conference-proceedings-of-spie/11878/1187820/Morph-targets-for-3D-facial-animation-with-webcam-using-Facemesh/10.1117/12.2600859.short?SSO=1">https://www.spiedigitallibrary.org/conference-proceedings-of-spie/11878/1187820/Morph-targets-for-3D-facial-animation-with-webcam-using-Facemesh/10.1117/12.2600859.short?SSO=1</a>  )
				  </p>
                </div>
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bx bx-cube-alt"></i>
                  <h4>Further development</h4>
                  <p>
                    Z-Motion X: Web app for Gesturing control 3D avatar model from Real-time or Video Body Tracking. 
                  </p>
                </div>
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bx bx-images"></i>
                  <h4>Research Ethics</h4>
                  <p>
                    The National Science and Technology Development Agency (NSTDA, Thailand) provided an ethical authorization document and approved this study for collecting face video data.
                  </p>
                </div>
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bx bx-shield"></i>
                  <h4>Funding</h4>
                  <p>
                    This research is under the project “ZFace Rigging : 3D Face Rigging from real-time face tracking using webcam”, which is financially supported by Target Output Profiles (TOP) 5: AI Service Platform, National Electronics and Computer Technology Center (NECTEC), National Science and Technology Development Agency (NSTDA), Thailand. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default forwardRef(About);
