import { useWindowScroll } from "@react-hook/window-scroll";
import { useEffect, useRef } from "react";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import { useStoreActions } from "../../store/Hooks";
import About from "./About";
import Hero from "./Hero";
import Services from "./Services";

export default function Home() {
  const aboutRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);
  const scrollY = useWindowScroll(30);
  const setNode = useStoreActions((actions) => actions.homeNavigate.setNode);

  useEffect(() => {
    const refs = [serviceRef, aboutRef];
    for (let i = 0; i < refs.length; i++) {
      const ref = refs[i];
      if (ref.current !== null) {
        if (scrollY >= ref.current.offsetTop - 10) {
          setNode(ref);
          break;
        }
      }
      if (i === refs.length - 1) {
        setNode({} as React.RefObject<HTMLDivElement>);
      }
    }
  }, [setNode, scrollY]);

  return (
    <DefaultLayout>
      <Hero></Hero>
      <About ref={aboutRef}></About>
      <Services ref={serviceRef}></Services>
      {/* <Counts></Counts> */}
    </DefaultLayout>
  );
}
