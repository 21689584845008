import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import GoToTopBtn from "../components/GoToTopBtn";
import ModalContainer from "../components/Modal/ModalContainer";
import { useStoreActions, useStoreState } from "./../store/Hooks";
import Callback from "./Callback/Callback";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import Home from "./Home/Home";
import Login from "./Login/Login";
import Profile from "./Profile/Profile";
import Register from "./Register/Register";
import TOS from './TOS/TOS';

export default function PageRoute() {
  const [ready, setReady] = useState(false);
  const restoreUser = useStoreActions((action) => action.user.restoreUser);
  const user = useStoreState((state) => state.user.user);

  useEffect(() => {
    restoreUser().finally(() => {
      setReady(true);
    });
  }, [restoreUser]);

  return (
    <>
      <ModalContainer />
      {ready && (
        <Router>
          <CookieConsent
            buttonStyle={{
              backgroundColor: "var(--secondary)",
              color: "var(--light)",
            }}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            {/* <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route> */}
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/profile">
              {user?._id ? <Profile /> : <Redirect to="/" />}
            </Route>
            <Route path="/callback">
              <Callback />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/forgotpassword">
              <ForgotPassword />
            </Route>
            <Route path="/tos">
              <TOS />
            </Route>
          </Switch>
          <GoToTopBtn />
        </Router>
      )}
    </>
  );
}
