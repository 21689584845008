import { Link } from "react-router-dom";

export default function DefaultInnerPage(props: DefaultInnerProps) {
  return (
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>{props.pagename}</h2>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>{props.pagename}</li>
            </ol>
          </div>
        </div>
      </section>

      <section className="inner-page">{props.children}</section>
    </main>
  );
}
type DefaultInnerProps = {
  children?: React.ReactNode;
  pagename: string;
};
