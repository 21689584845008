import DefaultInnerPage from "../../layout/DefaultInnerPage/DefaultInnerPage";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import ForgotPasswordForm from "./ForgotPasswordForm";

export default function ForgotPassword() {
  return (
    <DefaultLayout>
      <DefaultInnerPage pagename="Forgot Password">
        <ForgotPasswordForm></ForgotPasswordForm>
        <div style={{ height: 300 }} />
      </DefaultInnerPage>
    </DefaultLayout>
  );
}
