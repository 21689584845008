import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { IApiDataError, IApiDataSuccess } from "../../services/api.services";
import { IUser, IUserSigninLocal } from "../../store/UserModel";
import InputGroup from "./../../components/Form/InputGroup";
import { useStoreActions } from "./../../store/Hooks";
import "./LoginForm.scss";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  remember: yup.boolean(),
});

export default function LoginForm() {
  const { signin, resend, setUser } = useStoreActions((action) => action.user);
  const { alertError, alertSuccess } = useStoreActions(
    (action) => action.alert
  );
  const [errMsg, setErrMsg] = useState("");
  const [resendToMail, setResendToMail] = useState("");
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const history = useHistory();

  const handleOnSubmit = (e: IUserSigninLocal) => {
    signin(e)
      .then((resp: IApiDataSuccess<{ user: IUser }>) => {
        alertSuccess(resp);
        setUser(resp.data.user);
        setErrMsg("");
        history.push("/");
      })
      .catch((err: IApiDataError) => {
        if (err.statusCode === 401) {
          setResendToMail(e.email);
        } else {
          setResendToMail("");
        }
        setErrMsg(err.error);
        alertError(err);
      });
  };

  const handleResend = (e: Event) => {
    e.preventDefault()
    resend({ email: resendToMail })
      .then((resp: IApiDataSuccess<{ user: IUser }>) => {
        alertSuccess(resp);
        setErrMsg("");
        setResendToMail("");
      })
      .catch((err: IApiDataError) => {
        setErrMsg(err.error);
        alertError(err);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8 mx-auto">
          <h2>Login</h2>
          <p>
            Don't have an account? <Link to="/register">Sign Up</Link>
          </p>
          <div className="container">
            <div className="row">
              <div className="col-12 my-1">
                <button className="facebook-btn mx-auto">
                  <div className="facebook-before">
                    <i className="bx bxl-facebook"></i>
                  </div>
                  <div
                    className="facebook-name"
                    onClick={() =>
                      window.location.replace(
                        window.location.origin + "/api/v1/auth/facebook"
                      )
                    }
                  >
                    Sign In With Facbook
                  </div>
                </button>
              </div>
              <div className="col-12 my-1">
                <button className="google-btn mx-auto">
                  <div className="google-before">
                    <i className="bx bxl-google"></i>
                  </div>
                  <div
                    className="google-name"
                    onClick={() =>
                      window.location.replace(
                        window.location.origin + "/api/v1/auth/google"
                      )
                    }
                  >
                    Sign In With Google
                  </div>
                </button>
              </div>
            </div>
          </div>
          <p className="or">
            <span>or</span>
          </p>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <InputGroup
              label="Email"
              placeholder="Enter email"
              errors={errors}
              formState={formState}
              name="email"
              id="email1"
              type="email"
              register={register}
            />
            <InputGroup
              label="Password"
              placeholder="Enter password"
              errors={errors}
              formState={formState}
              name="password"
              id="password1"
              type="password"
              register={register}
            />
            <div className="form-group form-check">
              <input
                ref={register}
                type="checkbox"
                name="remember"
                className={classNames("form-check-input")}
                id="remember1"
              />
              <label className="form-check-label" htmlFor="remember1">
                Remember me
              </label>
            </div>
            <p>
              <span style={{ color: "var(--danger)" }}>{`${errMsg} `}</span>
              {resendToMail && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="pointer link-primary" onClick={(e: any) => handleResend(e)}>
                  {"Resend Validation Email"}
                </a>
              )}
            </p>
            <div className="row">
              <button type="submit" className="btn btn-primary mx-auto my-1">
                Submit
              </button>
            </div>
            <div className="row">
              <Link to="/forgotpassword" className="mx-auto">
                Forgot Password?
              </Link>
            </div>
            <hr></hr>
          </form>
        </div>
      </div>
    </div>
  );
}
