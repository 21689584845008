import { Action, action } from "easy-peasy";
import { IModalProp } from "../components/Modal/Modal";


export interface IModalModel {
  modals: IModalProp[]
  addModal: Action<IModalModel, IModalProp>
  removeModal: Action<IModalModel, string>

  alert: Action<IModalModel, {body: string, cb?: Function}>
}

export const modal: IModalModel = {
  modals: [],
  addModal: action((state, modalProp) => {
    state.modals.push(modalProp);
  }),
  removeModal: action((state, uid) => {
    for (let i = 0; i < state.modals.length; i++) {
      const modal = state.modals[i];
      if ( modal.uid === uid ) {
        state.modals.splice(i, 1);
        break;
      }
    }
  }),
  alert: action((state, {body, cb}) => {
    const uid = new Date().getTime().toString();
    const modalProp: IModalProp = { 
      title: "Alert", 
      body, 
      btn: "OK", 
      secondaryBtn: "Cancel", 
      uid,
      cb, 
    }
    state.modals.push(modalProp)
  }),
}