import { StoreProvider } from "easy-peasy";
import React, { useEffect } from "react";
import Loader from "./components/Loader";
import PageRoute from "./page/PageRoute";
import { webApi } from "./services/api.services";
import { store } from "./store/Store";

export default function App() {
  useEffect(() => {
    webApi.defaults.withCredentials = true;
    webApi.interceptors.request.use(
      function (config: any) {
        // spinning start to show
        // UPDATE: Add this code to show global loading indicator
        document.body.classList.add("loading");

        const token = window.localStorage.token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function (error: any) {
        return Promise.reject(error);
      }
    );

    webApi.interceptors.response.use(
      function (response: any) {
        // spinning hide
        // UPDATE: Add this code to hide global loading indicator
        document.body.classList.add("animate");
        setTimeout(() => {
          document.body.classList.remove("loading");
          document.body.classList.remove("animate");
        }, 5 * 100);
        return response.data;
      },
      function (error: any) {
        console.log(error);
        console.log(error.response);

        document.body.classList.add("animate");
        setTimeout(() => {
          document.body.classList.remove("loading");
          document.body.classList.remove("animate");
        }, 5 * 100);

        // // Do something with response error
        // if (error.response.status === 401) {
        //     console.log('unauthorized, logging out ...');
        //     auth.logout();
        //     router.replace('/auth/login');
        // }

        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <StoreProvider store={store}>
      <Loader />
      <PageRoute />
    </StoreProvider>
  );
}
