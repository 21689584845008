import { Action, action } from "easy-peasy";
import { IAlertProp } from "../components/Alert/Alert";
import { IApiDataError, IApiDataSuccess } from "../services/api.services";

type AlertType = "primary" | "secondary" | "success" | "warning" | "danger" | "info";

export interface IAlertModel {
  alerts: IAlertProp[]
  addAlert: Action<IAlertModel, IAlertProp>
  removeAlert: Action<IAlertModel, string>

  alert: Action<IAlertModel, {body: string, type: AlertType}>

  alertError: Action<IAlertModel, IApiDataError>
  alertSuccess: Action<IAlertModel, IApiDataSuccess>
}

export const alert: IAlertModel = {
  alerts: [],
  addAlert: action((state, alertProp) => {
    state.alerts.push(alertProp);
  }),
  removeAlert: action((state, uid) => {
    for (let i = 0; i < state.alerts.length; i++) {
      const alert = state.alerts[i];
      if ( alert.uid === uid ) {
        state.alerts.splice(i, 1);
        break;
      }
    }
  }),
  alert: action((state, {body, type}) => {
    const uid = new Date().getTime().toString();
    const alertProp: IAlertProp = { 
      uid,
      type,
      text: body,
    }
    state.alerts.push(alertProp)
  }),
  // 
  alertError: action((state, error) => {
    const uid = new Date().getTime().toString();
    const text = error.error || "Connection error.";
    let code = ""
    if ( error.statusCode ) {
      code = `(${error.statusCode})`
    }
    const alertProp: IAlertProp = { 
      uid,
      type: "danger",
      text: `${text} ${code}`,
    }
    state.alerts.push(alertProp)
  }),
  alertSuccess: action((state, resp) => {
    console.log(resp);
    
    const uid = new Date().getTime().toString();
    const alertProp: IAlertProp = { 
      uid,
      type: "primary",
      text: resp.message,
    }
    state.alerts.push(alertProp)
  })
}