import React from "react";
import { Route } from "react-router-dom";
import ChangePasswordForm from "../../components/Forms/ChangePasswordForm";
import DefaultInnerPage from "../../layout/DefaultInnerPage/DefaultInnerPage";
import DefaultLayout from "../../layout/DefaultLayout/DefaultLayout";
import ProfileForm from "./ProfileForm";

export default function Profile() {
  return (
    <DefaultLayout>
      <DefaultInnerPage pagename="Profile">
        <Route path="/profile" exact>
          <ProfileForm></ProfileForm>
        </Route>
        <Route path="/profile/change-password">
          <ChangePasswordForm changeBy="password"></ChangePasswordForm>
        </Route>
      </DefaultInnerPage>
    </DefaultLayout>
  );
}
