export default function Loading() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8">
          <p>
            <i className="bx bx-loader bx-spin bx-md"></i>
            Please wait...
          </p>
        </div>
      </div>
    </div>
  );
}
