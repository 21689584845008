export const EMAIL = "zface.rigging@gmail.com, chanjira.sinthanayothin@nectec.or.th"
export const PHONE = "081-2345678"

export const BASE_API_URL = "/api/v1/"

export const MENUS = [
  {
    name: "home",
    text: "Home",
    route: "/",
    hash: "#",
    scrollTo: "home",
  },
  {
    name: "about",
    text: "About Us",
    route: "/",
    hash: "#about",
    scrollTo: "about",
  },
  {
    name: "services",
    text: "Services",
    route: "/",
    hash: "#services",
    scrollTo: "services",
  },
  {
    name: "zfacerig",
    text: "Try Z-Face Rigging",
    route: "/",
    hash: "#zfacerig",
    scrollTo: "zfacerig",
  }
];