import classNames from 'classnames';

export const formClassnames = (formState: any, errors: any, name: string): string => {
  
  
  if (!formState.dirtyFields[name]) {
    return "form-control"
  }

  let isValid = true;
  if (errors[name]) {
    isValid = false;
  }
  return classNames("form-control", isValid ? "is-valid" : "is-invalid")
}