import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import * as yup from "yup";
import { regexPassword } from "../../constant/regex";
import { useQuery } from "../../hooks/useQuery";
import { IApiDataError, IApiDataSuccess } from "../../services/api.services";
import { useStoreActions } from "../../store/Hooks";
import { IEditPwdOld, IEditPwdToken } from "../../store/UserModel";
import InputGroup from "../Form/InputGroup";

const schema = yup.object().shape({
  token: yup.string(),
  old_password: yup.string().required("Required"),
  password: yup
    .string()
    .min(8, "Password is too short - should be 8-32 chars.")
    .matches(
      regexPassword,
      "Password can only be aphanumeric _ ! & @ # ? % $  / > < ) ( * ^"
    )
    .required("Required"),
});

type ChangePasswordFormProps = {
  changeBy: "token" | "password";
};

export default function ChangePasswordForm(props: ChangePasswordFormProps) {
  const changePassword = useStoreActions(
    (action) => action.user.changePassword
  );
  const { alertError, alertSuccess } = useStoreActions(
    (action) => action.alert
  );
  const query = useQuery();
  const history = useHistory();

  const { register, handleSubmit, errors, formState, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (e: IEditPwdToken | IEditPwdOld) => {
    changePassword(e)
      .then((resp: IApiDataSuccess) => {
        alertSuccess(resp);
        history.replace({ pathname: "/" });
      })
      .catch((err: IApiDataError) => {
        alertError(err);
      });
  };

  useEffect(() => {
    setValue("token", query.get("token"), {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [query, setValue]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8 mx-auto">
          <h2>Change Password</h2>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <InputGroup
              errors={errors}
              formState={formState}
              name="token"
              id="token1"
              type="password"
              hidden
              register={register}
            />
            <InputGroup
              errors={errors}
              label="Current Password"
              formState={formState}
              name="old_password"
              id="old_password1"
              type="password"
              hidden={props.changeBy !== "password"}
              register={register}
            />
            <InputGroup
              label="New Password"
              placeholder="Enter your new Password"
              errors={errors}
              formState={formState}
              name="password"
              id="password1"
              type="password"
              register={register}
              helper={"must be 8-32 aphanumeric _ ! & @ # ? % $  / > < ) ( * ^"}
            />
            <div className="row">
              <button type="submit" className="btn btn-primary mx-auto my-1">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
