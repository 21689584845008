import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import InputGroup from "../../components/Form/InputGroup";
import { IApiDataError, IApiDataSuccess } from "../../services/api.services";
import { IUserForgotLocal } from "../../store/UserModel";
import { useStoreActions } from "./../../store/Hooks";

const schema = yup.object().shape({
  email: yup.string().email().required("Required"),
});

export default function ForgotPasswordForm() {
  const forgot = useStoreActions((action) => action.user.forgot);
  const { alertError, alertSuccess } = useStoreActions(
    (action) => action.alert
  );
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (e: IUserForgotLocal) => {
    forgot(e)
      .then((resp: IApiDataSuccess) => {
        alertSuccess(resp);
      })
      .catch((err: IApiDataError) => {
        alertError(err);
      });
  };

  console.log(errors);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8 mx-auto">
          <h2>Forgot Password</h2>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <InputGroup
              label="Email"
              placeholder="Enter your email"
              errors={errors}
              formState={formState}
              name="email"
              id="email1"
              type="email"
              register={register}
            />
            <div className="row">
              <button type="submit" className="btn btn-primary mx-auto my-1">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
