import axios, { AxiosResponse } from "axios";
import { BASE_API_URL } from './../constant/app';

export interface IApiData {
  statusCode: number;
}

export interface IApiDataSuccess<T = any> extends IApiData {
  message: string;
  data: T;
}

export interface IApiDataError extends IApiData {
  error: any;
  trace?: any;
}

export const webApi = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'content-type': 'application/json; charset=utf-8',
    'accept': 'application/json, text/plain, */*'
  },
  transformResponse: [
    (data) => {
      let resp

      try {
        resp = JSON.parse(data)
      } catch (error) {
        throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`)
      }

      // resp.statusCode === 200 for error code
      // resp.data.statusCode === 200 for
      if (resp.statusCode === 200) {
        resp = resp as IApiDataSuccess
        return resp
      } else {

        // somehow is not wrapping with AxiosResponse
        // resp = resp as AxiosResponse<IApiDataError> 

        resp = resp as IApiDataError

        throw resp
      }
    }
  ]
});

// helper function that get nested data
export const parseData = (resp: AxiosResponse<IApiDataSuccess>) => {
  return resp.data.data
}
// webApi.prototype.parseData = (resp: AxiosResponse<IApiDataSuccess>) => {
//   return resp.data.data
// }

// if object have token then add it to headers
export const addBearerFromObject = (obj: any = {}): any => {
  if (obj?.token) {
    return { 'Authorization': `Bearer ${obj.token}` }
  }
  return {}
}