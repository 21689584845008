/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { HashLink as Link } from "react-router-hash-link";
import AlertContainer from "../../components/Alert/AlertContainer";
import ProfileCard from "../../components/ProfileCard";
import UserLetter from "../../components/UserLetter";
import { MENUS } from "../../constant/app";
import { useStoreState } from "../../store/Hooks";

const menus = MENUS;

export default function Header() {
  const location = useLocation();
  const homeNode = useStoreState((state) => state.homeNavigate.node);
  const [activeMenu, setActiveMenu] = useState("");

  const isLoggedIn = useStoreState((state) => state.user.isLoggedIn);
  const user = useStoreState((state) => state.user.user);

  // show profile
  const [showCard, setShowCard] = useState(false);
  const toggleShowCard = () => {
    setShowCard(!showCard);
  };

  // mobile
  const [mobileOpen, setMobileOpen] = useState(false);
  const toggleMobile = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (homeNode.current) {
      setActiveMenu(homeNode.current.id);
    } else {
      setActiveMenu("home");
    }
  }, [homeNode]);

  const shouldActive = (menu: any, homePath: string) => {
    if (location.pathname === menu.route && menu.route === homePath) {
      // for active at home path
      if (activeMenu === menu.name) {
        return true;
      }
    } else if (location.pathname === menu.route) {
      // for other path
      return true;
    }
    return false;
  };

  return (
    <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1 className="text-light">
              <Link smooth to="/#">
                <span>Z-Face Rigging</span>
              </Link>
            </h1>
          </div>

          <nav
            id="navbar"
            style={{
              position: mobileOpen ? "fixed" : "relative",
            }}
            className={classNames("navbar", {
              "navbar-mobile": mobileOpen,
            })}
          >
            <ul>
              {menus.map((menu) => {
                return (
                  <li key={menu.name}>
                    <Link
                      smooth
                      to={menu.hash ? menu.route + menu.hash : menu.route}
                      className={classNames("nav-link pointer", {
                        active: shouldActive(menu, "/"),
                      })}
                      onClick={() => setMobileOpen(false)}
                    >
                      {menu.text}
                    </Link>
                  </li>
                );
              })}
              <li className="m-first auth-dynamic-button">
                {isLoggedIn ? (
                  <div className="d-flex align-items-center">
                    <UserLetter name={user.name || ""} />
                    <i
                      className="bx bx-caret-down text-light pointer"
                      onClick={() => toggleShowCard()}
                    ></i>
                    <ProfileCard
                      style={{ display: showCard ? "flex" : "none" }}
                      user={user}
                      showSignOut={true}
                    />
                  </div>
                ) : (
                  <Link
                    smooth
                    to={"/login"}
                    className={classNames("nav-link pointer", {
                      active: shouldActive(
                        { name: "login", route: "/login" },
                        "/"
                      ),
                    })}
                    onClick={() => setMobileOpen(false)}
                  >
                    Login
                  </Link>
                )}
              </li>
              <li className="nav-link m-first">
                <div className="divider"></div>
              </li>
            </ul>
            <i
              onClick={() => toggleMobile()}
              className={classNames("bi mobile-nav-toggle", {
                "bi-list": !mobileOpen,
                "bi-x": mobileOpen,
              })}
            ></i>
          </nav>
        </div>
      </header>
      <AlertContainer />
    </>
  );
}
