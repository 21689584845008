import useScrollPosition from "@react-hook/window-scroll";
import cx from "classnames";
import { HashLink as Link } from "react-router-hash-link";
import "./GoToTopBtn.scss";

export default function GoToTopBtn() {
  const scrollY = useScrollPosition(60);
  return (
    <Link smooth to={"#"}>
      <button
        className={cx(
          "back-to-top d-flex align-items-center justify-content-center",
          { active: scrollY >= 60 }
        )}
      >
        <i className="bi bi-arrow-up-short"></i>
      </button>
    </Link>
  );
}
