import React, { Fragment, useEffect } from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import Modal from "./Modal";

export default function ModalContainer() {
  const modals = useStoreState((state) => state.modal.modals);
  const remove = useStoreActions((action) => action.modal.removeModal);

  useEffect(() => {
    console.log(modals);
  }, [modals]);

  return (
    <>
      {modals.length > 0 && (
        <div className="modal-container">
          {modals.map((modalProps, i) => {
            return (
              <Fragment key={i}>
                <Modal {...modalProps} remove={remove}></Modal>
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
}
