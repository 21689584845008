import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { HashLink as Link } from "react-router-hash-link";
import * as yup from "yup";
import InputGroup from "../../components/Form/InputGroup";
import { IApiDataError, IApiDataSuccess } from "../../services/api.services";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import { IEditProfileOld } from "../../store/UserModel";

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  // email: yup.string().email().required("Required"),
  // role: yup.string().required("Required"),
  old_password: yup.string().required("Required"),
});

export default function ProfileForm() {
  const editProfile = useStoreActions((action) => action.user.editProfile);
  const { user, isLocal, isLoggedIn } = useStoreState((state) => state.user);
  const { alertError, alertSuccess } = useStoreActions(
    (action) => action.alert
  );
  const { register, handleSubmit, errors, formState, setValue } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleOnSubmit = (e: IEditProfileOld) => {
    console.log(e);

    editProfile(e)
      .then((resp: IApiDataSuccess) => {
        alertSuccess(resp);
      })
      .catch((err: IApiDataError) => {
        alertError(err);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("role", user.role);
    }
  }, [isLoggedIn, setValue, user.email, user.name, user.role]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8 mx-auto">
          <h2>Profile</h2>
          <p>Logged as {user.loggedBy} account.</p>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <InputGroup
              label="Name"
              errors={errors}
              formState={formState}
              name="name"
              id="name1"
              type="text"
              disabled={!isLocal}
              register={register}
            />
            <InputGroup
              label="Email"
              errors={errors}
              formState={formState}
              name="email"
              id="email1"
              type="email"
              disabled
              register={register}
            />
            <InputGroup
              label="Role"
              errors={errors}
              formState={formState}
              name="role"
              id="role1"
              type="text"
              disabled
              register={register}
            />
            {isLocal && (
              <>
                <hr />
                <InputGroup
                  label="Current Password"
                  errors={errors}
                  formState={formState}
                  name="old_password"
                  id="old_password1"
                  type="password"
                  hidden={!isLocal}
                  register={register}
                />
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-3">
                    <Link to="/profile/change-password">
                      <button
                        type="button"
                        className="btn btn-secondary mx-auto my-1 w-100"
                      >
                        Change Password
                      </button>
                    </Link>
                  </div>
                  <div className="col-12 col-sm-3">
                    <button
                      type="submit"
                      className="btn btn-primary mx-auto my-1 w-100"
                    >
                      Confirm Change
                    </button>
                  </div>
                </div>
              </>
            )}
            <hr />
          </form>
        </div>
      </div>
    </div>
  );
}
