import React, { Fragment, useEffect } from "react";
import { useStoreActions, useStoreState } from "../../store/Hooks";
import Alert from "./Alert";

const destroyEvery = 7 * 1000;

export default function AlertContainer() {
  const alerts = useStoreState((state) => state.alert.alerts);
  const remove = useStoreActions((action) => action.alert.removeAlert);

  useEffect(() => {
    if (alerts.length > 0) {
      setTimeout(() => {
        const uid = alerts.slice().shift()?.uid;
        if (uid) {
          remove(uid);
        }
      }, destroyEvery);
    }
  }, [alerts, remove]);

  return (
    <>
      {alerts.length > 0 && (
        <div className="alert-container">
          {alerts.map((alertProps, i) => {
            return (
              <Fragment key={i}>
                <Alert {...alertProps}></Alert>
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
}
