import { useHistory } from "react-router";
import { HashLink as Link } from "react-router-hash-link";
import { IApiDataError, IApiDataSuccess } from "../services/api.services";
import { useStoreActions } from "../store/Hooks";
import { IUser } from "../store/UserModel";
import UserLetter from "./UserLetter";

type IProfileCardProps = {
  user: IUser;
  style?: React.CSSProperties;
  showSignOut?: boolean;
};

export default function ProfileCard(props: IProfileCardProps) {
  const { user, style, showSignOut } = props;
  const { alertSuccess, alertError } = useStoreActions(
    (action) => action.alert
  );
  const signout = useStoreActions((action) => action.user.signout);
  const setUser = useStoreActions((action) => action.user.setUser);
  const history = useHistory();

  const handleSignOut = () => {
    signout()
      .then((resp: IApiDataSuccess) => {
        alertSuccess(resp);
        setUser({} as IUser);
        history.push("/");
      })
      .catch((err: IApiDataError) => {
        alertError(err);
      });
  };

  const getShortBio = (user: IUser): string => {
    if (user.email) {
      return user.email;
    }
    // TODO: get bio from social profile
    return "";
  };

  return (
    <div
      style={{ display: "flex", ...style }}
      className="profile-card flex-column align-items-center"
    >
      <div className="d-flex justify-content-center">
        <UserLetter name={user.name || "Anonymous"} />
      </div>
      <div>
        <b>{user.name || "Anonymous"}</b>
      </div>
      <div>
        Role: <span style={{ textTransform: "capitalize" }}>{user.role}</span>
      </div>
      <div>{getShortBio(user)}</div>
      <Link smooth to="/profile">
        View Profile
      </Link>
      {showSignOut && (
        <button
          className="btn btn-outline-primary my-2"
          onClick={() => handleSignOut()}
        >
          Sign Out
        </button>
      )}
    </div>
  );
}
