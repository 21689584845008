import { useEffect, useState } from "react";
import "./UserLetter.scss";

type UserLetterProps = {
  name: string;
};

export default function UserLetter(props: UserLetterProps) {
  const [letter, setLetter] = useState("");
  const { name } = props;
  useEffect(() => {
    if (name.length > 0) {
      setLetter(name.charAt(0));
    }
  }, [name]);

  return <div className="user-letter">{letter}</div>;
}
