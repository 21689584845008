type ErrMessageProps = {
  error?: {
    message: string;
    type: string;
  };
};

export default function ErrMessage(props: ErrMessageProps) {
  return <div className="invalid-feedback">{props?.error?.message}</div>;
}
