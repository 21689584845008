import { forwardRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
// import HeroVideo from "../../assets/video/hero-video.mp4";
import VidDemo from "../../assets/video/newDemo.mp4";
import "./Hero.scss";

function Hero(props: any, ref: any) {
  return (
    <section ref={ref} id="hero">
      <video autoPlay muted loop className="hero-video">
        <source src={VidDemo} type="video/mp4"></source>
      </video>
      <div className="hero-overlay"></div>
      <div className="hero-container" data-aos="fade-up">
        <h1>Z-Face Rigging</h1>
        <h2>We manipulate model's face</h2>
        <Link to="/#about" className="btn btn-get-started scrollto">
          <i className="bx bx-chevrons-down"></i>
        </Link>
      </div>
    </section>
  );
}

export default forwardRef(Hero);
