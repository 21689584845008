import FadeIn from "react-fade-in";

export type IAlertProp = {
  uid: string;
  text: string;
  type: string;
};

export default function Alert(props: IAlertProp) {
  return (
    <FadeIn>
      <div className={`alert ${"alert-" + props.type}`} role="alert">
        {props.text}
      </div>
    </FadeIn>
  );
}
