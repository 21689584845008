import { useEffect, useState } from "react";
import { useQuery } from "../../hooks/useQuery";

export default function Facebook() {
  const query = useQuery();

  const [msg, setMsg] = useState("");

  useEffect(() => {
    const type = query.get("type");
    if (type === "no-email") {
      setMsg(
        "Login failed. An email address is required. Please try login with email permission."
      );
    }
  }, [query]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8">
          <h3>Facebook Login</h3>
          <p>{msg}</p>
        </div>
      </div>
    </div>
  );
}
