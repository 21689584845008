export default function GoneWrong() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-8 col-lg-8">
          <h3>Something went wrong. Please try again later.</h3>
        </div>
      </div>
    </div>
  );
}
