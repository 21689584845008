import { createStore } from "easy-peasy";
import { alert, IAlertModel } from './AlertModel';
import { homeNavigate, IHomeNavigateModel } from './HomeNavigateModel';
import { IModalModel, modal } from './ModalModel';
import { IUserModel, user } from './UserModel';

export interface IModel {
  homeNavigate: IHomeNavigateModel;
  modal: IModalModel;
  alert: IAlertModel;
  user: IUserModel;
}

export const model = {
  homeNavigate,
  modal,
  alert,
  user
}

export const store = createStore<IModel>(model);
