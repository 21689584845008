import { formClassnames } from "../../utils/form-validator";
import ErrMessage from "./ErrMessage";

type InputGroupProps = {
  register: any;
  formState: any;
  errors: any;
  name: string;
  placeholder?: string;
  type: string;
  id: string;
  label?: string;
  helper?: string;
  disabled?: boolean;
  hidden?: boolean;
};

export default function InputGroup(props: InputGroupProps) {
  if (props.hidden) {
    return <></>;
  }
  return (
    <div className="form-group">
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <input
        ref={props.register}
        type={props.type}
        name={props.name}
        className={formClassnames(props.formState, props.errors, props.name)}
        id={props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        hidden={props.hidden}
      />
      {props.helper && (
        <small id="emailHelp" className="form-text text-muted">
          {props.helper}
        </small>
      )}
      <ErrMessage error={props.errors[props.name]}></ErrMessage>
    </div>
  );
}
